<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-model="filter.country"
                            :items='country'
                            :label='$t("País")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            @input="fetchState"
                        )
                        v-select.mb-3(
                            v-model="filter.state"
                            :items='state'
                            :label='$t("Estado/Departamento/Provincia")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            @input="fetchCity"
                        )
                        v-select.mb-3(
                            v-model="filter.city"
                            :items='city' 
                            :label='$t("Ciudad/Municipio")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                            v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{ $t('Cancelar')}}
                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                            v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t("Cancelar")}}
                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Estadísticas')}}
                    .stats
                        .stats-item(v-for="item in stats")
                            v-icon.secondary--text {{ item.icon }}
                            .stats-item--content
                                strong.primary--text {{ item.number }}
                                span {{ $t(item.text) }}
                    v-btn.primary.mt-4.mx-0.mb-0(small :loading="loadChart" depressed @click="fetchDetailChart($root.profile.uuid)") {{$t('Mi distribución de referidos')}}
                    v-btn.primary.mt-4.mx-2.mb-0(small :loading="loadRank" depressed @click="fetchDetailRank($root.profile.uuid)") {{$t('Mis rankings')}}
                    v-btn.primary.mt-4.mx-2.mb-0(small depressed @click="$refs.total.open($root.profile)") {{$t('Total usuarios invitados')}}
                    //v-btn.primary.mt-4.mx-2.mb-0(small depressed @click="$refs.notes.open($root.profile)") {{$t('Mensajes de la red')}}
                    v-divider.mt-4
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    v-data-table(v-else
                        :items='network'
                        :headers='headers'
                        :pagination.sync='pagination'
                        :rows-per-page-items="$root.rowsPageItems"
                        :rows-per-page-text="$root.rowsPageText" 
                        item-key='total'
                        :total-items="total"
                        :loading="loading"
                    )
                        template(slot="no-results")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot="no-data")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot='headers' slot-scope='props')
                            tr  
                                template(v-for='header in props.headers')
                                    //Deshabilitar para usuario
                                    template(v-if="['phone', 'email', 'city'].includes(header.value) && profile.uuid == '4a6b0ac0-649f-5dee-8373-12758619c316'")
                                    th(v-else :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" @click='changeSort(header.value)' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                                        v-icon(small) arrow_upward
                        template(slot='items' slot-scope='props')
                            tr
                                td 
                                    v-layout
                                        v-flex
                                            v-avatar.grey.lighten-4(size='27')
                                                img(v-if="photoUrl(props.item.photo).length" :src="photoUrl(props.item.photo)")
                                                v-gravatar(v-else :email="props.item.email")
                                        v-flex(xs12 d-flex align-center)
                                            | {{ props.item.names }}
                                td
                                   a(target="blank" v-if="props.item.whatsapp.length" :href="props.item.whatsapp") {{$t('Enlace para hacer crecer tu red')}}
                                td 
                                    template
                                        v-progress-circular.text-xs-center(:size="24" v-if="props.item.loadChart" indeterminate color="primary")
                                        v-icon.cur-p.primary--text(v-else @click="fetchDetailChart(props.item.uuid, props.item)") pie_chart
                                    template
                                        v-progress-circular.text-xs-center(:size="24" v-if="props.item.loadRank" indeterminate color="primary")
                                        v-icon.cur-p.primary--text(v-else @click="fetchDetailRank(props.item.uuid, props.item)") star
                                        | {{ props.item.total }}
                                td {{ props.item.totalshare }}
                                td {{ props.item.code }}
                                td
                                    | {{$t('Fecha')}}: {{ props.item.datecreated | moment('YYYY-MM-DD') }} 
                                    br
                                    | {{$t('Hora')}}: {{ props.item.datecreated | moment('HH:mm') }} 
                                td(v-if="profile.uuid != '4a6b0ac0-649f-5dee-8373-12758619c316'") {{ props.item.phone }}
                                td(v-if="profile.uuid != '4a6b0ac0-649f-5dee-8373-12758619c316' && isAvailable('cityid')") {{ props.item.city }}
                                td(v-if="isAvailable('zones')" style="min-width:220px")
                                    template(v-for="zone in props.item.zones")
                                        small {{zone.division}}: {{zone.name}}
                                        br
                                td(v-if="isAvailable('birthdate')") {{ props.item.age }}
                                td(v-if="profile.uuid != '4a6b0ac0-649f-5dee-8373-12758619c316'")
                                    a.email-table {{ props.item.email }}
                                td(v-if="isAvailable('document')") {{ props.item.document }}
                                td {{ props.item.network }}
                                
                                // Valores redes rodolfistas
                                template(v-if="isRodolfista")
                                    template(v-for="(field, k) in props.item.field")
                                        td(v-if="isAvailable(k)") {{ field }}

                                template(v-if="Object.keys(props.item.custom).length")
                                    td(v-for="c in props.item.custom")
                                        | {{ c }}
                        template(slot='pageText', slot-scope='props')
                            | {{$t('Filas')}} {{ props.pageStart }} - {{ props.pageStop }} {{$t('de')}} {{ props.itemsLength }}
                    // Deshabilitar para algunos usuarios
                    v-btn(v-if="!isRodolfista && profile.uuid != '4a6b0ac0-649f-5dee-8373-12758619c316'" :loading="loadingBtn" @click="download" fixed dark fab bottom left color='primary')
                        v-icon file_download
            chart(ref="chart" :referral="true")
            rank(ref="rank")
            total(ref="total")
            notes(ref="notes")
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import _ from 'lodash/debounce'
    import fn from 'mixins/fn'

    export default {
        mixins: [auth, fn],
        metaInfo() {
            return {
                title: this.$t('Estadísticas')
            }
        },
        data() {
            return {
                total: 0,
                loading: false,
                pagination: {
                    page: 1,
                    rowsPerPage: 20,
                    sortBy: 'total',
                    descending: true
                },
                drawerRight: false,
                loadingBtn: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    country  : '',
                    state    : '',
                    city     : '',
                    from     : null,
                    to       : null
                },
                indicator: {
                    share: 0,
                    network: 0,
                    rankCountry: 0,
                    rankState: 0,
                    rankCity: 0
                },
                network: [],
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'names',
                    },
                    {
                        text: 'Enlace para hacer crecer tu red',
                        align: 'left',
                        value: 'whatsapp',
                        sortable: false
                    },
                    {
                        text: 'Total de red',
                        align: 'left',
                        value: 'total'
                    },
                    {
                        text: 'Total de referidos',
                        align: 'left',
                        value: 'totalshare'
                    },
                    {
                        text: 'Código de referido',
                        align: 'left',
                        value: 'code'
                    },
                    {
                        text: 'Fecha de registro',
                        align: 'left',
                        value: 'datecreated'
                    },
                    {
                        text: 'Celular',
                        align: 'left',
                        value: 'phone'
                    },
                    {
                        text: 'Ciudad/Municipio',
                        align: 'left',
                        value: 'city'
                    },
                    {
                        text: 'Zonas',
                        align: 'left',
                        value: 'zone',
                        sortable: false
                    },
                    {
                        text: 'Edad',
                        align: 'left',
                        value: 'age'
                    },
                    {
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email'
                    },
                    {
                        text: 'Número de documento de identificación',
                        align: 'left',
                        value: 'document'
                    },
                    {
                        text: 'Red',
                        align: 'left',
                        value: 'network'
                    }
                ],
                country: [],
                state: [],
                city: [],
                loadChart: false,
                loadRank: false,
                preload: false
            }
        },
        computed: {
            stats(){ 
                return [
                    { 
                        icon: 'person', 
                        text: 'Personas aceptaron tu invitación', 
                        number: this.indicator.share  
                    },
                    { 
                        icon: 'language', 
                        text: 'Se han registrado por medio de tu red', 
                        number: this.indicator.network 
                    },
                    { 
                        icon: 'poll', 
                        text: 'Ranking nacional', 
                        number: this.indicator.rankCountry 
                    },
                    { 
                        icon: 'poll', 
                        text: 'Ranking regional', 
                        number: this.indicator.rankState 
                    },
                    { 
                        icon: 'poll', 
                        text: 'Ranking local', 
                        number: this.indicator.rankCity 
                    },
                ]
            },
            isRodolfista(){
                return this.$root.profile.isRodolfista
            }
        },
        watch: {
            filter: {
                handler(){
                    this.preload = true
                    this.total = 0
                    this.network = []
                    this.fetchNetwork()
                },
                deep: true
            },
            pagination: {
                handler () {
                    this.fetchNetwork()
                },
                deep: true
            },
            profile: {
                handler(){
                    this.fetchForm()
                },
                deep: true
            }
        },
        created(){
            
            this.fetchIndicator()
            this.fetchCountries()
            this.filter = Object.assign({}, this.filterSchema)
        },
        methods: {
            
            photoUrl(photo){

                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },

            fetchDetailChart(uuid, item={}){

                if(Object.keys(item).length){
                    item.loadChart = true
                }else{
                    this.loadChart = true
                }

                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail?uuid='+uuid).then((r) => {

                        if(Object.keys(item).length){
                            item.loadChart = false
                        }else{
                            this.loadChart = false
                        }

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.$refs.chart.open(itemData)

                    }).catch(() => {
                        if(Object.keys(item).length){
                            item.loadChart = false
                        }else{
                            this.loadChart = false
                        }
                    })
                })
            },

            fetchDetailRank(uuid, item={}){

                if(Object.keys(item).length){
                    item.loadRank = true
                }else{
                    this.loadRank = true
                }

                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-rank', {
                        params: {
                            uuid,
                            key: 'ever'
                        }
                    }).then((r) => {

                        let merge = {}
                        if(Object.keys(item).length){
                            item.loadRank = false
                            merge = item
                        }else{
                            this.loadRank = false
                            merge = this.$root.profile
                        }

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.$refs.rank.open(Object.assign(this._.cloneDeep(merge), itemData))

                    }).catch(() => {
                        if(Object.keys(item).length){
                            item.loadRank = false
                        }else{
                            this.loadRank = false
                        }
                    })
                })
            },

            fetchIndicator(){

                this.$api(this, (xhr) => {
                    xhr.get('/report/indicator').then((r) => {

                        let data = r.data
                        if(data.response){
                            this.indicator = Object.assign({}, data.data)
                        }
                    }).catch(() => {})
                })
            },

            fetchCountries(){

                this.$api(this, (xhr) => {
                    xhr.get('/country').then((r) => {

                        let data = r.data
                        if(data.response){
                            this.country = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(){

                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.filter.country 
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.state = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchCity(){

                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.filter.state 
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.city = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchForm() {
                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: {
                            networkid : this.profile.networkid,
                            type      : 1
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {
                            
                            let customForm = data.data.custom

                            this.availableRow = data.data.available || []

                            if(!this.isAvailable('cityid')){

                                let idx = this.headers.findIndex(h => h.value == 'city')
                                this.headers.splice(idx, 1)
                            }
                            if(!this.isAvailable('zones')){
                                
                                let idx = this.headers.findIndex(h => h.value == 'zone')
                                this.headers.splice(idx, 1)
                            }
                            if(!this.isAvailable('birthdate')){
                                
                                let idx = this.headers.findIndex(h => h.value == 'age')
                                this.headers.splice(idx, 1)
                            }
                            if(!this.isAvailable('document')){
                                
                                let idx = this.headers.findIndex(h => h.value == 'document')
                                this.headers.splice(idx, 1)
                            }
                            if(!this.isAvailable('gender')){
                                
                                let idx = this.headers.findIndex(h => h.value == 'gender')
                                this.headers.splice(idx, 1)
                            }

                            this.setFields()

                            for(let c of customForm){
                                this.headers.push({
                                    text  : c.placeholder,
                                    align : 'left',
                                    value : c.uuid,
                                    sortable: false
                                })
                            }
                        }
                    }).catch(() => {})
                })
            },

            fetchNetwork: _(function(){

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()

                this.loading = true
                this.$api(this, (xhr) => {
                    xhr.get('/report/network', {
                        params: {
                            stat: 1,
                            filter : JSON.stringify(this.filter),
                            limit  : this.pagination.rowsPerPage,
                            offset : (this.pagination.page-1)*this.pagination.rowsPerPage,
                            sort   : this.pagination.sortBy,
                            order  : (this.pagination.descending ? 'DESC' : 'ASC'),
                        }
                    }).then((r) => {

                        let data = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){
                            let network  = data.data.result.map((data) => {
                                data.loadChart = false
                                data.loadRank = false
                                return data
                            })
                            this.total = data.data.total
                            this.network = network
                        }
                    }).catch(() => {})
                })
            }, 500),
            changeSort (column) {
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            download(){
                
                this.loadingBtn = true
                let userid = this.$root.profile.uuid

                this.$api(this, (xhr) => {
                    xhr.post('/report/network/xls?stat=1').then((r) => {

                        let data = r.data
                        this.loadingBtn = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                    }).catch(() => {})
                })
            },

            /**
             * Obtener cabeceras y campos por filtro de red rodolfista
             */
            setFields(){

                if(!this.isRodolfista){
                    return
                }

                const fieldHeaders = [
                    {
                        text  : this.$t('Departamento donde estudió'),
                        value : 'field_state'
                    },
                    {
                        text  : this.$t('Ciudad donde estudió'),
                        value : 'field_city'
                    },
                    {
                        text  : this.$t('Colegio donde estudió'),
                        value : 'field_school'
                    },
                    {
                        text  : this.$t('Universidad donde estudió'),
                        value : 'field_institution'
                    },
                    {
                        text  : this.$t('Grados recibidos o por recibir de pregado o postgrado'),
                        value : 'field_grade'
                    },
                    {
                        text  : this.$t('Área de estudio'),
                        value : 'field_area'
                    },
                    {
                        text  : this.$t('Otra área de estudio'),
                        value : 'field_other_area'
                    }
                ]

                for(let f of fieldHeaders){

                    if(this.headers.some((h) => h.value == f.value)){
                        continue
                    }

                    let isEnabled = this.availableRow.some(t => t.tag == f.value && t.enable == 1)
                    if(!isEnabled){
                        return
                    }

                    this.headers.push({
                        text    : f.text,
                        align   : 'left',
                        value   : f.value,
                        sortable: false
                    })
                }
            }
        }
    }

</script>